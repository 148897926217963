/* eslint-disable react/react-in-jsx-scope */
import {
  ClockConfig,
  HeaderWorldClock,
  HomePageCompanyLogo,
} from '@backstage/plugin-home';
import {
  HomePageToolkit,
  HomePageStarredEntities,
  TemplateBackstageLogo,
  TemplateBackstageLogoIcon,
} from '@backstage/plugin-home';
import { Content, Page, Header } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  // searchApiRef,
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import { DevQuote } from '@parsifal-m/plugin-dev-quotes-homepage';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();
  const clockConfigs: ClockConfig[] = [
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
    {
      label: 'NYC',
      timeZone: 'America/New_York',
    },
    {
      label: 'TOR',
      timeZone: 'America/Toronto',
    },
    {
      label: 'LAG',
      timeZone: 'Africa/Lagos',
    },
    {
      label: 'RIO',
      timeZone: 'America/Sao_Paulo',
    },
    {
      label: 'LON',
      timeZone: 'Europe/London',
    },
    {
      label: 'SYD',
      timeZone: 'Australia/Sydney',
    },
    {
      label: 'ARG',
      timeZone: 'America/Argentina/Buenos_Aires',
    },
  ];


  return (
    <SearchContextProvider>
      <Header title="Welcome to Backstage" pageTitleOverride="Home">
        <HeaderWorldClock clockConfigs={clockConfigs} />
      </Header>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: '#',
                      label: 'link',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={12} xs={12}>
              <DevQuote />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
