import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  analyticsApiRef,
  configApiRef,
  createApiFactory,
  identityApiRef
} from '@backstage/core-plugin-api';
import { apiDocsConfigRef, defaultDefinitionWidgets, OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { AndelaTechRadarApiClient } from './components/tech-radar/api-client/AndelaTechRadarApIClient';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) => 
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      const interceptor = (req: Request) => {
        req.headers.set("apikey", process.env.API_DOCS_API_KEY || "api-key-not-set");
        return req;
      }
      const definitionWidgets = defaultDefinitionWidgets().map(obj => {
        if (obj.type === 'openapi') {
          return {
            ...obj,
            component: (definition: any) => <OpenApiDefinitionWidget definition={definition} requestInterceptor={interceptor} />,
          };
        }
        return obj;
      });

      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new AndelaTechRadarApiClient())
];
